@font-face {
  font-display: swap;
  font-family: 'font awesome 5 pro';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/fontawesome/fa-light-300.eot);
  src: url(./fonts/fontawesome/fa-light-300.eot?#iefix) format('embedded-opentype'),
    url(./fonts/fontawesome/fa-light-300.woff2) format('woff2'),
    url(./fonts/fontawesome/fa-light-300.woff) format('woff'),
    url(./fonts/fontawesome/fa-light-300.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'font awesome 5 brands';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/fontawesome/fa-brands-400.eot);
  src: url(./fonts/fontawesome/fa-brands-400.eot?#iefix) format('embedded-opentype'),
    url(./fonts/fontawesome/fa-brands-400.woff2) format('woff2'),
    url(./fonts/fontawesome/fa-brands-400.woff) format('woff'),
    url(./fonts/fontawesome/fa-brands-400.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: gilroy;
  src: url(./fonts/gilroy/Gilroy-Regular.eot);
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url(./fonts/gilroy/Gilroy-Regular.eot?#iefix) format('embedded-opentype'),
    url(./fonts/gilroy/Gilroy-Regular.woff2) format('woff2'), url(./fonts/gilroy/Gilroy-Regular.woff) format('woff'),
    url(./fonts/gilroy/Gilroy-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: gilroy;
  src: url(./fonts/gilroy/Gilroy-Bold.eot);
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url(./fonts/gilroy/Gilroy-Bold.eot?#iefix) format('embedded-opentype'),
    url(./fonts/gilroy/Gilroy-Bold.woff2) format('woff2'), url(./fonts/gilroy/Gilroy-Bold.woff) format('woff'),
    url(./fonts/gilroy/Gilroy-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: gilroy;
  src: url(./fonts/gilroy/Gilroy-Light.eot);
  src: local('Gilroy Light'), local('Gilroy-Light'),
    url(./fonts/gilroy/Gilroy-Light.eot?#iefix) format('embedded-opentype'),
    url(./fonts/gilroy/Gilroy-Light.woff2) format('woff2'), url(./fonts/gilroy/Gilroy-Light.woff) format('woff'),
    url(./fonts/gilroy/Gilroy-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: gilroy;
  src: url(./fonts/gilroy/Gilroy-Extrabold.eot);
  src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
    url(./fonts/gilroy/Gilroy-Extrabold.eot?#iefix) format('embedded-opentype'),
    url(./fonts/gilroy/Gilroy-Extrabold.woff2) format('woff2'), url(./fonts/gilroy/Gilroy-Extrabold.woff) format('woff'),
    url(./fonts/gilroy/Gilroy-Extrabold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'), url('./fonts/pretendard/PretendardStd-Bold.woff2') format('woff2'),
    url('./fonts/pretendard/PretendardStd-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'), url('./fonts/pretendard/PretendardStd-Medium.woff2') format('woff2'),
    url('./fonts/pretendard/PretendardStd-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'), url('./fonts/pretendard/PretendardStd-Regular.woff2') format('woff2'),
    url('./fonts/pretendard/PretendardStd-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'), url('./fonts/pretendard/PretendardStd-Light.woff2') format('woff2'),
    url('./fonts/pretendard/PretendardStd-Light.woff') format('woff');
}

*,
*:after,
*:before {
  -webkit-tap-highlight-color: transparent;
  list-style-position: inside;
  list-style-type: none;
  color: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  position: relative;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Pretendard', gilroy, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
figure,
figcaption,
a,
button {
  font-size: inherit;
  color: inherit;
  background: transparent;
}

body,
th,
td,
input,
select,
textarea,
button {
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Pretendard', gilroy, 'Helvetica Neue', sans-serif;
  line-height: 1;
  word-break: break-word;
  letter-spacing: -0.03em;
}

section,
article,
header,
footer,
nav,
aside {
  display: block;
}

table,
td,
blockquote,
code,
pre,
textarea,
input {
  max-width: 100%;
}

input,
textarea {
  resize: none;
  -webkit-appearance: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'],
[type='file'] {
  -webkit-appearance: button;
  border-radius: 0;
}

img {
  height: auto;
  vertical-align: bottom;
}

i {
  font-style: normal;
}

a,
button {
  touch-action: manipulation;
  border: 0;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: 0.25s;
}

a,
a:hover,
a:active,
a:focus,
button,
button:hover,
button:focus,
button:active {
  text-decoration: none;
  outline: none;
}

address {
  font-style: normal;
}

strong {
  font-weight: 700;
}

[data-link] {
  cursor: pointer;
}

[data-bg] {
  background-position: center center;
  background-size: cover;
}

.sf-toolbar {
  display: none !important;
}

.fx {
  margin-top: 0 !important;
}

.fxb {
  margin-top: 0 !important;
  top: auto !important;
  bottom: 0 !important;
}

.js-color-bg {
  fill: #000;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: inherit !important;
}

*::-moz-selection {
  background-color: #3f00e6;
  color: #edf1f3;
}

*::selection {
  background-color: #3f00e6;
  color: #edf1f3;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

::-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

.fa:before {
  font-family: 'font awesome 5 pro';
  font-weight: 300;
}

.fab:before {
  font-family: 'font awesome 5 brands';
}

.down:before {
  content: '\f33d';
}

.phone:before {
  content: '\f095';
}

.heart:before {
  content: '\f004';
}

.envelope:before {
  content: '\f0e0';
}

.marker:before {
  content: '\f041';
}

.at:before {
  content: '\f1fa';
}

.times:before {
  content: '\f00d';
}

.cookie:before {
  content: '\f563';
}

.infos:before {
  content: '\f05a';
}

.lal:before {
  content: '\f177';
}

.instagram:before {
  content: '\f16d';
}

.facebook:before {
  content: '\f39e';
}

.linkedin:before {
  content: '\f0e1';
}

.twitter:before {
  content: '\f099';
}

.awr:before {
  content: '\f178';
}

.awl:before {
  content: '\f177';
}

.awd:before {
  content: '\f175';
}

.check:before {
  content: '\f00c';
}

.coffe:before {
  content: '\f7b6';
}

[data-cursor] {
  mix-blend-mode: difference;
  display: none;
  pointer-events: none;
  width: 0;
  height: 0;
  margin-top: 2px;
  margin-left: 2px;
  z-index: 99999999;
  position: absolute;
  top: 0;
  left: 0;
}

.eth [data-cursor] {
  mix-blend-mode: normal;
}

html:hover [data-cursor] {
  display: block;
}

html.ie [data-cursor],
html.mob [data-cursor] {
  display: none !important;
}

[data-cursor] .cur1 {
  width: 5px;
  height: 5px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  z-index: 5;
}

[data-cursor] .cur1 > * {
  opacity: 0;
  transition: 0.4s;
  font-weight: 800;
  font-size: 14px;
  color: #edf1f3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  text-align: center;
}

.eth [data-cursor] .cur1 > .see {
  opacity: 1;
}

[data-cursor] .cur2 {
  transition: opacity 0.4s, transform 0.4s;
  opacity: 0.3;
  width: 15px;
  height: 15px;
  background-color: #edf1f3;
  margin: -11px 0 0 -10px;
  border-radius: 50%;
}

.hover [data-cursor] .cur2,
.drag [data-cursor] .cur2 {
  transform: scale(4);
  opacity: 0.1;
}

.eth [data-cursor] .cur2 {
  background-color: #000;
  opacity: 1;
  transform: scale(4);
}

body.eth * {
  cursor: none !important;
}

.swiper-slide {
  overflow: hidden;
  flex: 1 0 auto;
  height: 100%;
}

.swiper-wrapper {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

@font-face {
  font-display: swap;
  font-family: icomoon;
  src: url(./fonts/icomoon/icomoon.eot);
  src: url(./fonts/icomoon/icomoon.eot?#iefix) format('embedded-opentype'),
    url(./fonts/icomoon/icomoon.woff2) format('woff2'), url(./fonts/icomoon/icomoon.woff) format('woff'),
    url(./fonts/icomoon/icomoon.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

[class^='i_']:before,
[class*=' i_']:before {
  font-family: icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.i_cdc:before {
  content: '\e900';
}

.i_cm:before {
  content: '\e901';
}

.i_comm-digi:before {
  content: '\e902';
}

.i_conseil-audit:before {
  content: '\e903';
}

.i_conversion:before {
  content: '\e904';
}

.i_creation:before {
  content: '\e905';
}

.i_dev:before {
  content: '\e906';
}

.i_ecommerce:before {
  content: '\e907';
}

.i_fideliser:before {
  content: '\e908';
}

.i_graphique:before {
  content: '\e909';
}

.i_graphisme:before {
  content: '\e90a';
}

.i_identite:before {
  content: '\e90b';
}

.i_image:before {
  content: '\e90c';
}

.i_logo:before {
  content: '\e90d';
}

.i_marketing:before {
  content: '\e90e';
}

.i_marketing-digital:before {
  content: '\e90f';
}

.i_marketplace:before {
  content: '\e910';
}

.i_nouvelle-techno:before {
  content: '\e911';
}

.i_print:before {
  content: '\e912';
}

.i_projet-global:before {
  content: '\e913';
}

.i_refonte:before {
  content: '\e914';
}

.i_ref-seo:before {
  content: '\e915';
}

.i_rs:before {
  content: '\e916';
}

.i_site:before {
  content: '\e917';
}

.i_vitrau:before {
  content: '\e918';
}

.i_vitrine:before {
  content: '\e919';
}

.i_adwords:before {
  content: '\e91a';
}

.i_app-pwa:before {
  content: '\e91b';
}

.i_art-digi:before {
  content: '\e91c';
}

.i_autre-objectif:before {
  content: '\e91d';
}

.i_autre-souhait:before {
  content: '\e91e';
}

html,
body {
  font-size: calc(13px + 2 * (100vw - 320px) / 1080);
  background-color: #edf1f3;
  color: #16141c;
  line-height: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Pretendard', gilroy, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

.scrollbar-track {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s, background 0.3s;
  position: absolute;
  z-index: 9999;
  background-color: #eee;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scrollbar-track:hover,
.scrollbar-track.show {
  opacity: 1;
}

.landh .scrollbar-track,
.scrollbar-track.show {
  opacity: 1;
}

.scrollbar-track-y {
  height: 100%;
  width: 3px;
  top: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
}

.scrollbar-track-y .scrollbar-thumb {
  cursor: pointer;
  pointer-events: auto;
  width: 8px;
  left: -2px;
  border-radius: 5px 0 0 5px;
}

.scrollbar-track-y .scrollbar-thumb:before {
  content: '';
  width: 4px;
  height: 100%;
  left: 1px;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px 0 0 5px;
}

.landh .scrollbar-track-y .scrollbar-thumb:before {
  background: #16141c !important;
  border-radius: 5px 0 0 5px;
}

#ck {
  overflow: hidden;
  background-color: #008aff;
  color: #edf1f3;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  transition: transform 0.6s, opacity 0.6s, visibility 0.6s;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  max-width: calc(99vw - 20px);
  position: fixed;
  bottom: calc(10px + 0.5vw);
  left: calc(10px + 0.5vw);
  z-index: 9990;
  width: 400px;
  font-size: 11px;
  line-height: 1.3;
}

.cks.fix #ck {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

#ck .ckc {
  display: flex;
  align-items: flex-start;
  padding: 20px;
}

#ck p strong {
  display: block;
  margin-bottom: 10px;
  font-size: 130%;
  line-height: 1;
}

#ck a {
  font-weight: 700;
  text-decoration: underline;
}

#ck a:hover {
  color: #222;
}

#ck i {
  color: #edf1f3;
  font-size: 35px;
  margin-right: 15px;
  top: -8px;
}

#ck i:before {
  animation: cookies 1s infinite linear;
}

#ck .ckb {
  display: flex;
  font-size: 15px;
  font-weight: 700;
}

#ck .ckb > * {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  flex: 1;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 14px 0 15px;
}

#ck .ckb > *:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

#ck .ckb > * + * {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

#ck .ckb small {
  font-size: 13px;
  font-weight: 400;
}

@keyframes cookies {
  0% {
    content: '\f563';
  }

  100% {
    content: '\f564';
  }
}

.preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: #fff;
}

.preloader img {
  max-width: 100%;
  width: 150px;
}

.ct {
  width: 80vw;
  margin: auto;
}

.trac * {
  fill: none;
  stroke: #16141c;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.tc {
  color: #16141c;
  pointer-events: none;
  font-weight: 700;
  user-select: none;
  -moz-user-select: none;
  width: calc(90px + 25 * (100vw - 320px) / 1080);
  height: calc(90px + 25 * (100vw - 320px) / 1080);
  pointer-events: none;
}

.tc svg {
  fill: #16141c;
  animation: rotate 8s infinite linear;
  position: absolute;
  width: 150%;
  height: 150%;
  left: -25%;
  top: -25%;
}

.tc i {
  font-size: calc(20px + 10 * (100vw - 320px) / 1080);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tc i.is {
  font-size: calc(25px + 10 * (100vw - 320px) / 1080);
}

.tc i.awr {
  animation: arrow 1s infinite linear;
}

.tc i.awd {
  animation: arrowd 1s infinite linear;
}

@keyframes arrow {
  0% {
    margin-left: -5px;
  }

  50% {
    margin-left: 5px;
  }

  100% {
    margin-left: -5px;
  }
}

@keyframes arrowd {
  0% {
    margin-top: -5px;
  }

  50% {
    margin-top: 5px;
  }

  100% {
    margin-top: -5px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.btn {
  display: inline-block;
  padding: 0 0 calc(20px + 3 * (100vw - 320px) / 1080);
  font-weight: 400;
  font-size: calc(12px + 1 * (100vw - 320px) / 1080);
  letter-spacing: calc(1px + 2 * (100vw - 320px) / 1080);
  text-transform: uppercase;
  transition: 0s;
}

.btn span {
  transition: 0.2s;
  z-index: 5;
  display: block;
}

.btn:active span {
  transform: scale(0.95);
}

.btn:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  height: 1px;
  left: calc(-1px - 1.5 * (100vw - 320px) / 1080);
}

.btn.blck:after,
.btn.blck-ln:after {
  background: rgba(0, 0, 0, 0.1);
}

.btn:before {
  transition: 0.8s;
  z-index: 3;
  content: '';
  position: absolute;
  bottom: -1px;
  width: 40px;
  height: 3px;
  border-radius: 0% 0% 0% 0%;
  left: calc(-1px - 1.5 * (100vw - 320px) / 1080);
}

.btn.blck:before {
  background: linear-gradient(to left, #f9224b, #ed213a);
}

.btn:hover:before {
  width: 100%;
}

.scroll {
  position: absolute;
  bottom: calc(25px + 2%);
  left: calc(15px + 2%);
  height: calc(40px + 15 * (100vw - 320px) / 1080);
}

.scroll > div {
  width: 3px;
  background-color: #edf1f3;
  height: 100%;
}

.scroll > div:after,
.scroll > div:before {
  content: '';
  bottom: -2px;
  position: absolute;
  background: inherit;
  height: 25%;
  width: inherit;
}

.scroll > div:before {
  transform: rotate(-40deg);
  transform-origin: bottom left;
  border-bottom-left-radius: 3px;
}

.scroll > div:after {
  transform: rotate(40deg);
  transform-origin: bottom right;
  border-bottom-right-radius: 3px;
}

#app {
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

html:not(.horiz) #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 3;
}

html:not(.horiz) #app > .scroll-content {
  width: 100%;
  height: 100%;
  z-index: 5;
}

html:not(.horiz) #app main {
  width: 100%;
  z-index: 6000;
}

html:not(.horiz) #app .app-c {
  overflow: hidden;
}

#sc .h1,
#cn .h1 {
  margin-top: calc(25px + 10 * (100vw - 320px) / 1080);
  margin-bottom: -10px;
  text-transform: uppercase;
  font-size: calc(12px + 1 * (100vw - 320px) / 1080);
  letter-spacing: calc(2px + 1 * (100vw - 320px) / 1080);
}

#cn .h1 {
  margin-top: calc(15px + 5 * (100vw - 320px) / 1080);
  margin-bottom: calc(15px + 5 * (100vw - 320px) / 1080);
}

#sc .h1 h1,
#cn .h1 h1 {
  font-weight: 300;
}

.cirbc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.cirbc .cirb,
.cirbc .cirbr {
  width: 150vw;
  height: 150vw;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cirbc .cirbr {
  background-color: #16141c;
  width: 0;
  height: 0;
}

.cirbc svg {
  fill: none;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
  opacity: 0;
  width: 100px;
  fill: transparent;
  stroke-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.cirbc .ico {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #edf1f3;
}

.cirbc .ico i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.sp {
  height: 100vh;
  pointer-events: none;
}

.sp-3 {
  height: 30vh;
}

#e {
  padding-top: 50vh;
  color: #fff;
  text-align: left;
  pointer-events: none;
}

#e h2 span {
  display: block;
}

.eavl {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  text-align: right;
}

.eavl > * {
  flex: 1;
  margin: auto;
}

.eavl .ct {
}

.eavl h2 {
  width: 50%;
  left: calc(-80px - 30 * (100vw - 320px) / 1080);
  font-size: calc(25px + 15 * (100vw - 320px) / 1080);
  font-weight: 400;
}

.eavl h2 span {
  display: block;
}

.eavl h2 strong {
  font-weight: 800;
}

#h,
.she {
  position: absolute;
  display: flex;
  pointer-events: none;
  align-items: center;
  justify-content: space-between;
  z-index: 9997;
  width: 100%;
  padding: calc(15px + 2%) calc(15px + 2%) calc(15px + 2%) calc(25px + 2%);
  pointer-events: none;
}

#h .h1 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-110%);
}

.she {
  z-index: 9998;
  justify-content: flex-end;
}

.she a {
  margin-left: auto;
  pointer-events: auto;
}

#h.mixb {
  z-index: 500;
  mix-blend-mode: difference;
}

#h.mixb .bn {
  pointer-events: none;
}

#h.hcl,
.mix:not(.onav) #h {
  mix-blend-mode: difference;
}

#h > *:not(#n) {
  pointer-events: auto;
  z-index: 10;
}

#h > .r {
  margin-left: auto;
  display: flex;
  align-items: center;
}

#h .brf {
  font-size: calc(14px + 3 * (100vw - 320px) / 1080);
  font-weight: 800;
  color: #edf1f3;
  display: inline-block;
  margin-right: 20px;
  opacity: 0;
  visibility: hidden;
  left: 30px;
  transition: 0.6s 0.1s;
}

#h .brf i {
  margin-left: 10px;
}

.onav #h .brf {
  opacity: 1;
  left: 0;
  visibility: visible;
  transition: 0.6s 0.5s;
}

#h .lg {
  width: calc(160px + 30 * (100vw - 320px) / 1080);
  display: block;
  transition: 0.4s;
}

#h .logo {
  width: 100%;
  overflow: hidden;
  transition: width 0.4s, opacity 0.2s;
}

/* html:not(.creaOpen) .fix:not(.onav) #h:not(:hover) .logo {
    width: 36.2%
} */

#h .logo span {
  font-size: 24px;
  font-weight: 600;
  color: #edf1f3;
}

#h .logo svg {
  fill: #edf1f3;
  transition: fill 0.4s;
  width: calc(160px + 30 * (100vw - 320px) / 1080);
}

body[class*='blck-']:not(.onav) #h .logo span,
body:not(.onav) #h.blck-1 .logo span {
  color: #16141c;
}

body[class*='whte-']:not(.onav) #h .logo span {
  color: #edf1f3 !important;
}

body[class*='blck-']:not(.onav) #h .logo svg,
body:not(.onav) #h.blck-1 .logo svg {
  fill: #16141c;
}

body[class*='whte-']:not(.onav) #h .logo svg {
  fill: #edf1f3 !important;
}

.bn {
  transition: opacity 0.2s;
  cursor: pointer;
  width: calc(55px + 7 * (100vw - 320px) / 1080);
  height: calc(55px + 7 * (100vw - 320px) / 1080);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bn > div {
  width: calc(22px + 4 * (100vw - 320px) / 1080);
  height: 10px;
}

.bn > div > span {
  transition: background 0.4s;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #edf1f3;
}

body[class*='blck-']:not(.onav) .bn > div > span,
body:not(.onav) #h.blck-1 .bn > div > span {
  background-color: #16141c;
}

body[class*='whte-']:not(.onav) .bn > div > span {
  background-color: #edf1f3;
}

.bn > div > span:nth-child(1) {
  top: 0;
  transition: background 0.4s, top 0.2s 0.2s, transform 0.2s;
}

.bn.clos > div > span:nth-child(1),
html.creaOpen .bn > div > span:nth-child(1) {
  top: 4px;
  transform: rotate(45deg);
}

.onav .bn > div > span:nth-child(1),
.bn.closH > div > span:nth-child(1) {
  top: 4px;
  transform: rotate(45deg);
  transition: background 0.4s, bottom 0.2s, transform 0.2s 0.2s;
}

.onav:not(.fonav) .bn:hover > div > span:nth-child(1) {
  transform: rotate(0);
  transition: background 0.4s, transform 0.2s;
}

.bn > div > span:nth-child(2) {
  bottom: 0;
  transition: background 0.4s, bottom 0.2s 0.2s, transform 0.2s;
}

.bn.clos > div > span:nth-child(2),
html.creaOpen .bn > div > span:nth-child(2) {
  bottom: 4px;
  transform: rotate(-45deg);
}

.bn.closH > div > span:nth-child(2),
.onav .bn > div > span:nth-child(2) {
  bottom: 4px;
  transform: rotate(-45deg);
  transition: background 0.4s, bottom 0.2s, transform 0.2s 0.2s;
}

.onav:not(.fonav) .bn:hover > div > span:nth-child(2) {
  transform: rotate(0);
  transition: background 0.4s, transform 0.2s;
}

.bn svg {
  mix-blend-mode: normal;
  pointer-events: none;
  transition: all 0.8s, opacity 0s 0.8s;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
  fill: transparent;
  stroke-width: 4px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.onav .bn svg,
.bn:hover svg {
  stroke-dasharray: 300 999;
  opacity: 1;
  transition: all 0.8s, opacity 0s;
  transform: rotate(90deg);
}

#we:not(.r) {
  z-index: 100;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: linear-gradient(120deg, #27ae60, #00947e);
  color: #edf1f3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(50px + 5%) 0;
}

#we .wec {
  width: 70%;
  z-index: 10;
}

#we .wec .h1 {
  margin-top: calc(25px + 15 * (100vw - 320px) / 1080);
  margin-bottom: calc(-5px - 10 * (100vw - 320px) / 1080);
  text-transform: uppercase;
  font-size: calc(14px + 1 * (100vw - 320px) / 1080);
  letter-spacing: calc(2px + 1 * (100vw - 320px) / 1080);
}

#we .wec .h1 h1 {
  font-weight: 300;
}

#we .wem {
  font-size: calc(16px + 30 * (100vw - 320px) / 1080);
  font-weight: 800;
  line-height: 1.6;
  white-space: nowrap;
  margin-top: calc(15px + 2%);
  margin-bottom: calc(30px + 4%);
}

#we .wem > div {
  overflow: hidden;
  margin-bottom: calc(0px - 15 * (100vw - 320px) / 1080);
}

#we .wem > div > div {
  display: flex;
  align-items: center;
}

#we .wem .wew {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: transparent;
}
.wew div:last-child {
  font-size: calc(14px + 30 * (100vw - 320px) / 1080);
}

html:not(.ie) #we .wem .wew > div {
  color: #ff033e;
}

#we .wem .wew > div {
  transform: translateY(0%);
  position: absolute;
  top: 0;
}

#we .wem .wew > div.active {
  transform: translateY(0%);
}

#we .tc svg {
  fill: #edf1f3;
}

#we .screen {
  pointer-events: none;
  position: absolute;
  right: calc(-25px - 30 * (100vw - 320px) / 1080);
  width: calc(500px + 300 * (100vw - 320px) / 1080);
  top: 47%;
  transform: translateY(-50%);
  z-index: 0;
}

#we .screen .sc {
  width: 100%;
}

#we .screen .kb {
  width: 60%;
  position: absolute;
  bottom: -2%;
  left: 3%;
}

#we .screen .ms {
  width: 17%;
  position: absolute;
  bottom: -8%;
  left: 55%;
}

#we .sm {
  overflow: hidden;
  padding: 10px;
  z-index: 100;
  width: 65.6%;
  height: 57%;
  background-color: #110f16;
  position: absolute;
  top: 22.7%;
  left: 21%;
  transform: rotateY(6deg) skewY(26.4deg) skewX(-10.5deg);
  border-radius: 3px;
}

#we .sm:before {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

#we .sm * {
  position: absolute;
  border-radius: 3px;
}

#we .smc {
  height: calc(100% - 30px);
  top: 15px;
  width: calc(100% - 30px);
  left: 15px;
}

#we .smc .smc_h {
  background-color: #16141c;
  width: 100%;
  top: 0;
  height: 13%;
}

#we .smc .smc_lg1 {
  left: 3.5%;
  height: 8%;
  top: 5%;
}

#we .smc .smc_lg > div {
  width: calc(7px + 5 * (100vw - 320px) / 1080);
  height: calc(10px + 5 * (100vw - 320px) / 1080);
  border-radius: 50%;
}

#we .smc .smc_lg > div:nth-child(2) {
  left: calc(10px + 5 * (100vw - 320px) / 1080);
}

#we .smc .smc_lg > div:nth-child(3) {
  left: calc(20px + 10 * (100vw - 320px) / 1080);
}

#we .smc .smc_u {
  height: 1.3%;
  top: 6%;
  right: 5%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#we .smc .smc_u li {
  position: relative;
  width: 20%;
  height: 100%;
}

#we .smc .smc_u div {
  width: 100%;
  height: 100%;
  background-color: #24212d;
  right: 0;
  top: 0;
}

#we .smc .smc_h {
  background-color: #16141c;
  width: 100%;
  top: 0;
  height: 13%;
  display: flex;
  align-items: center;
}

#we .smc .smc_s {
  width: 100%;
  top: 15%;
  height: 50%;
  display: flex;
  align-items: center;
}

#we .smc .smc_s > div {
  width: 80%;
  height: 30%;
  left: 10%;
}

#we .smc .smc_s-sub {
  background-color: #24212d;
  width: 30%;
  top: 0;
  height: 6%;
  display: flex;
  align-items: center;
}

#we .smc .smc_s-tit {
  width: 50%;
  top: 30%;
  height: 20%;
  display: flex;
  align-items: center;
}

#we .smc .smc_s-tit.t2 {
  width: 40%;
  top: 60%;
  height: 20%;
  display: flex;
  align-items: center;
}

#we .smc .smc_a {
  width: 20%;
  left: 0;
  height: 42%;
  background-color: #16141c;
  top: 65%;
}

#we .smc .smc_a-c {
  width: 18%;
  left: 1%;
  height: 40%;
  top: 65%;
  z-index: 5;
  padding: 10px;
}

#we .smc .smc_a-c li {
  width: 80%;
  height: 2%;
  background-color: #24212d;
  position: relative;
  margin-top: 10%;
}

#we .smc .smc_a-c li.fi {
  width: 100%;
  margin-top: 20%;
}

#we .smc .smc_c4 {
  width: 41%;
  right: 50%;
  height: 30%;
  background-color: #16141c;
  top: 135%;
}

#we .smc .smc_c1 {
  width: 78%;
  height: 42%;
  background-color: #16141c;
  top: 65%;
}

#we .smc .smc_cv div {
  width: 100%;
  height: 1%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(20deg);
  transform-origin: center center;
  background-color: #24212d;
}

#we .smc .smc_cv div:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-20deg);
}

#we .smc .smc_c4 div {
  transform: translate(-50%, -50%) rotate(30deg);
}

#we .smc .smc_c4 div:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-30deg);
}

#we .smc .smc_c2 {
  width: 50%;
  height: 20%;
  left: 5%;
  background-color: #110f16;
  top: 95%;
}

#we .smc .smc_c3 {
  width: 40%;
  height: 50%;
  left: 10%;
  top: 100%;
}

#we .smc .smc_c3 div {
  position: relative;
  margin-top: 3%;
  width: 100%;
  height: 1%;
  position: relative;
  background-color: #24212d;
}

#we .smc .smc_c3 div.fi {
  height: 2%;
}

#we .smc .smc_c3 div.fim {
  width: 80%;
}

#we .smc .smc_c3 div.fim + .fi {
  margin-top: 15%;
}

#we .smc .smc_c3 div.fi + div:not(.fi) {
  margin-top: 6%;
}

#we .smc .smc_ul {
  display: flex;
  top: 175%;
  width: 100%;
  height: 35%;
  left: 1%;
}

#we .smc .smc_ul div {
  position: relative;
  height: 100%;
  width: 33.33%;
  max-width: 33.33%;
  flex-basis: 33.33%;
}

#we .smc .smc_ul div > span {
  display: block;
  width: 0;
  height: 100%;
  background-color: #16141c;
  position: relative;
}

#we .smc .smc_ul span > span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: block;
  border: 2px solid #edf1f3;
  width: calc(30px + 20 * (100vw - 320px) / 1080);
  height: calc(35px + 20 * (100vw - 320px) / 1080);
}

#we .smc .smc_f {
  width: 100%;
  height: 1px;
  top: 226%;
  left: 0%;
}

#we .smc .smc_f > div {
  width: 40%;
  height: 3px;
  background-color: #24212d;
  left: 48%;
  transform: translateX(-50%);
}

#we .smc .smc_f > div:nth-child(2) {
  width: 25%;
  top: calc(7px + 3 * (100vw - 320px) / 1080);
}

#we .smc .smc_lg2 {
  left: 44%;
  height: 8%;
  top: 218%;
}

#we .down {
  height: 70px;
  width: 2px;
  background-color: #edf1f3;
  margin-top: calc(30px + 3%);
}

[data-work] {
  pointer-events: auto;
  cursor: pointer;
}

#wr {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 8888;
  align-items: center;
  justify-content: center;
  color: #edf1f3;
  text-align: center;
}

#wr .bg {
  position: absolute;
  bottom: 0;
  height: 0;
  width: 100%;
  opacity: 1;
  background: linear-gradient(120deg, #27ae60, #00947e);
  border-radius: 3px;
}

#wr .wrc {
  position: absolute;
  width: 1000px;
  height: 600px;
  z-index: 1;
  border-radius: 3px;
  overflow: hidden;
}

#wr .wrb {
  z-index: 10;
  padding: calc(25px + 5%);
  height: 100%;
}

#wr .wrb h2 {
  font-size: calc(25px + 15 * (100vw - 320px) / 1080);
  font-weight: 800;
  line-height: 1.3;
  overflow: hidden;
}

#wr .wrb h2 span {
  display: inline-block;
}

#wr .wrb h3 {
  font-size: calc(17px + 8 * (100vw - 320px) / 1080);
  font-weight: 200;
  margin-bottom: calc(25px + 3%);
}

#wr .wrb h4 {
  font-size: calc(16px + 6 * (100vw - 320px) / 1080);
  font-weight: 800;
}

#wr .wrl {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.01);
  background: rgba(0, 0, 0, 0.1);
}

#wr .wrl .line {
  position: absolute;
  width: 0;
  height: 4px;
}

#wr .wrp {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

#wr .wrp ul {
  margin-top: calc(15px + 2%);
  font-size: calc(12px + 2 * (100vw - 320px) / 1080);
  display: flex;
  white-space: nowrap;
}

#wr .wrp ul li {
  paddin: 0 10px 10px;
}

#wr .ckb {
  transition: 0.2s;
  padding: calc(15px + 1%) calc(20px + 1%) calc(13px + 1%);
  font-weight: 800;
  display: inline-block;
  border: 2px solid transparent;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
}

#cl {
  color: #16141c;
  transition: color 0.8s 0.3s;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: #edf1f3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wcl #cl {
  color: #edf1f3;
  pointer-events: none;
}

#cl .clc {
  z-index: 5;
  display: none;
}

#cl .tit {
  z-index: 10;
  overflow: hidden;
  font-weight: 800;
  font-size: calc(18px + 30 * (100vw - 320px) / 1080);
  line-height: 1.2;
  letter-spacing: -1px;
  margin-bottom: calc(10px + 5 * (100vw - 320px) / 1080);
}

#cl .tit > * {
  display: inline-block;
}

#cl .tit .s2 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  transform: translateY(100%);
}

#cl p {
  font-size: calc(13px + 4 * (100vw - 320px) / 1080);
}

#cl .logo {
  display: none;
  z-index: 5;
  width: calc(100px + 20 * (100vw - 320px) / 1080);
  position: absolute;
  bottom: calc(15px + 10 * (100vw - 320px) / 1080);
  left: 50%;
  transform: translateX(-50%);
}

#cl .logo svg {
  transition: fill 0.8s 0.2s;
  fill: #16141c;
}

html:not(.ie) #cl .logo svg {
  height: 20px;
}

.wcl #cl .logo svg {
  fill: #edf1f3;
}

#cl .bg {
  z-index: 1;
  background-color: red;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  left: 0;
  overflow: hidden;
}

#cl .bg > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#cl .clb {
  margin-top: calc(25px + 10 * (100vw - 320px) / 1080);
}

#cl [data-color] {
  transition: 0s !important;
  width: calc(30px + 5 * (100vw - 320px) / 1080);
  height: calc(30px + 5 * (100vw - 320px) / 1080);
  background-color: #16141c;
  display: inline-block;
  margin: 0 6px;
  border-radius: 50%;
  cursor: pointer;
}

#cl [data-color]:before {
  opacity: 0;
  transition: 0.2s;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #16141c;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  left: -4px;
  top: -4px;
  content: '';
}

#cl [data-color]:hover:before {
  opacity: 1;
}

#ld {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ld .bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#ld .logo {
  width: calc(180px + 100 * (100vw - 320px) / 1080);
  z-index: 5;
  overflow: hidden;
}

#ld .logo svg {
  fill: #edf1f3;
}

#n {
  color: #edf1f3;
  display: none;
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 5;
}

.onav #n {
  pointer-events: auto;
}

#n > * {
  width: 50%;
  height: 100%;
}

#n > * > * {
  z-index: 5;
}

#n .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  z-index: 0;
}

#n .nr .bg {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

#n .nr .bg {
  background: linear-gradient(to top, #27ae60, #00947e);
}

#n .nl .bg {
  background: linear-gradient(to top, #27ae60, #00947e);
}

#n .nl {
  display: flex;
  align-items: center;
  justify-content: center;
}

#n .nl .nlc {
  width: 100%;
  font-weight: 800;
  padding-left: calc(25px + 15%);
  line-height: 1.3;
}

#n .nl nav li {
  overflow: hidden;
  margin-top: calc(24px + 5 * (100vw - 320px) / 1080);
}

#n .nl nav .link {
  display: inline-block;
  font-weight: 700;
  font-size: calc(25px + 23 * (100vw - 320px) / 1080);
  line-height: 1;
  transform: translateY(0%);
  transition: color 0.3s;
}

#n .nl nav .link:hover {
  color: #16141c;
}

#n .ms {
  transform: translateY(50px);
  opacity: 0;
  flex-wrap: wrap;
  padding: 0 calc(15px + 1%);
  display: flex;
  font-weight: 400;
  font-size: calc(11px + 2 * (100vw - 320px) / 1080);
  position: absolute;
  bottom: calc(10px + 10 * (100vw - 320px) / 1080);
  left: 0;
  text-align: center;
  justify-content: center;
  width: 100%;
}

#n .ms li {
  margin-bottom: 10px;
}

#n .ms li:not(:last-child):after {
  content: '';
  width: 3px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
  display: inline-block;
  margin: 0 10px;
  border-radius: 50%;
  top: -2px;
}

#n .ms a {
  display: inline-block;
  font-size: calc(14px + 3 * (100vw - 320px) / 1080);
  font-weight: 800;
  color: #edf1f3;
}
#n .ms a i {
  margin-left: 10px;
}

@media screen and (min-width: 551px) {
  #n .ms a {
    display: none;
  }
}

#n .nr {
  display: flex;
  align-items: center;
}

#n .nr .nrc {
  padding: 0 calc(25px + 15%);
}

#n .nr .art li {
  margin-top: calc(25px + 5%);
}

#n .nr .art a {
  transition: color 0.4s;
  line-height: 1.2;
  display: inline-block;
  font-size: calc(16px + 8 * (100vw - 320px) / 1080);
  font-weight: 800;
}

#n .nr .art a span {
  display: inline-block;
}

#n .nr .art time {
  color: #888;
  opacity: 0.6;
  font-size: calc(12px + 2 * (100vw - 320px) / 1080);
  display: block;
  margin-bottom: calc(15px + 1%);
}

#n .nr .tc svg {
  fill: #edf1f3;
}

#n .nr .rs {
  display: flex;
  font-size: calc(18px + 5 * (100vw - 320px) / 1080);
  margin-top: calc(30px + 5%);
}

#n .nr .rs li + li {
  margin-left: 20px;
}

#sv {
  min-height: 100vh;
}

#sv .rg {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  padding-left: 65%;
}

#sv .cir {
  width: 76vh;
  height: 76vh;
  border-radius: 50%;
  top: 12vh;
}

#sv .circ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #cdcccf;
}

#sv .circm {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
}

#sv .rnd {
  width: 1px;
  height: 1px;
  position: absolute;
}

#sv .rnd > div {
  width: calc(120px + 50 * (100vw - 320px) / 1080);
  height: calc(120px + 50 * (100vw - 320px) / 1080);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sv .rnd > div > div {
  transform-origin: center center;
  box-shadow: 0 1px 3px 1px rgba(99, 0, 243, 0.2);
  width: 5%;
  height: 5%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sv .rnd i {
  font-size: calc(50px + 30 * (100vw - 320px) / 1080);
  color: #edf1f3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sv .crc {
  position: absolute;
}

#sv .crc > div {
  position: absolute;
  width: 100%;
  height: 100%;
}

#sv .crc:nth-child(1) {
  height: 1px;
  width: 38vh;
  right: 0;
}

#sv .crc:nth-child(1) > div {
  right: 0;
}

#sv .crc:nth-child(1) .rnd {
  left: 0;
}

#sv .crc:nth-child(2) {
  height: 38vh;
  width: 1px;
  bottom: 0;
}

#sv .crc:nth-child(2) > div {
  bottom: 0;
}

#sv .crc:nth-child(2) .rnd {
  top: 0;
}

#sv .crc:nth-child(3) {
  height: 1px;
  width: 38vh;
  left: 0;
}

#sv .crc:nth-child(3) > div {
  left: 0;
}

#sv .crc:nth-child(3) .rnd {
  right: 0;
}

#sv .crc:nth-child(4) {
  height: 38vh;
  width: 1px;
  top: 0;
}

#sv .crc:nth-child(4) > div {
  top: 0;
}

#sv .crc:nth-child(4) .rnd {
  bottom: 0;
}

#sv .lgt {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 50%;
  padding-left: 15%;
}

#sv .lgt h3 {
  font-weight: 200;
  font-size: calc(20px + 10 * (100vw - 320px) / 1080);
  color: #111;
  line-height: 1.3;
}

#sv .lgt h3 strong {
  font-weight: 800;
  display: block;
  font-size: 130%;
}

#sv .lgt .tx {
  width: 80%;
  margin: calc(16px + 5%) 0;
  font-size: calc(16px + 2 * (100vw - 320px) / 1080);
  font-weight: 500;
  color: #333;
  line-height: 1.6;
  word-break: keep-all;
  letter-spacing: 0.25px;
}

#sv .lgt .tx ul {
  margin-top: 1rem;
}

#sv .lgt .tx li {
  margin-bottom: 0.5rem;
  font-size: calc(16px + 2 * (100vw - 320px) / 1080);
  line-height: 1.4;
  text-align: left;
}

#sv .lgb {
  height: 100vh;
}

#sv .lgb5 {
  height: 25vh;
}

.sp-5 {
  position: absolute;
  top: 0;
}

.lgtxt {
  color: #edf1f3;
  text-align: center;
  z-index: 100;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 200;
}

.lgtxt > div {
  width: 100%;
}

.lgtxt h2 {
  margin: 0 auto calc(25px + 3%);
  font-weight: 200;
  font-size: calc(25px + 20 * (100vw - 320px) / 1080);
  width: 90%;
}

.lgtxt h2 strong {
  font-weight: 800;
}

.lgtxt .step {
  white-space: nowrap;
  font-size: calc(50px + 50 * (100vw - 320px) / 1080);
  font-weight: 700;
}

.lgtxt .stepc {
  position: absolute;
  left: 100%;
}

.lgtxt .stepc span {
  font-weight: 200;
  font-style: italic;
}

.lgtxc {
  text-align: left;
  height: 100vh;
  min-height: 600px;
  max-height: 950px;
  display: flex;
  align-items: center;
}

.lgtxc .ct > div {
  width: calc(50% - 100px);
  left: calc(50% + 100px);
}

.lgtxc h3 {
  font-size: calc(18px + 10 * (100vw - 320px) / 1080);
  font-weight: 200;
}

.lgtxc h3 > * {
  display: block;
}

.lgtxc h3 > strong {
  font-weight: 800;
}

.lgtxc .txt {
  width: 100%;
  margin: calc(25px + 2%) auto 0;
  font-weight: 400;
  font-size: calc(16px + 2 * (100vw - 320px) / 1080);
  line-height: 1.8;
  color: #edf1f3;
  word-break: keep-all;
}

.lgtxc .txt strong {
  font-weight: 800;
}

.lgtxt .tc {
  position: absolute;
  bottom: 0;
  margin-bottom: calc(15px + 30 * (100vh - 320px) / 1080);
}

.lgtxt svg {
  fill: #edf1f3;
}

#co {
  padding: calc(30px + 8%) 0;
  text-align: center;
  color: #edf1f3;
  z-index: 5000;
}

#co .ct {
  z-index: 1;
}

#co h2 {
  font-weight: 800;
  margin-bottom: calc(20px + 3%);
  line-height: 1.3;
}

#co h2 small {
  font-size: calc(20px + 10 * (100vw - 320px) / 1080);
}

#co h2 span {
  display: inline-block;
}

#co h2 strong {
  font-weight: 200;
  overflow: hidden;
  font-size: calc(26px + 30 * (100vw - 320px) / 1080);
  display: block;
  margin-bottom: 5px;
}

#co .bf {
  margin-top: calc(16px + 3%);
}

#co .bf a {
  position: relative;
  display: inline-block;
  z-index: 1;
  overflow: hidden;
  padding: calc(16px + 3 * (100vw - 320px) / 1080);
  font-weight: 600;
  color: #fff;
  background-color: #000;
  border-radius: 24px;
  box-shadow: rgba(23, 73, 77, 0.15) 0 20px 30px;
}

#co .bf a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #ed213a;
  transform: scale3d(1, 0, 1);
  transition: transform 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: top;
}

#co .bf a:hover {
  color: rgba(255, 255, 255, 1);
  transform: translate3d(0, -2px, 0);
}

#co .bf a:hover:after {
  transform: scale3d(1, 1, 1);
}

#co .bf .down {
  margin-left: 10px;
}

#co fieldset {
  background: #edf1f3;
  width: calc(300px + 100 * (100vw - 320px) / 1080);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 50px;
}

#co fieldset + fieldset {
  margin-top: 27px;
}

#co fieldset.txt {
  overflow: hidden;
  width: calc(400px + 100 * (100vw - 320px) / 1080);
}

#co fieldset > label {
  padding-top: 18px;
}

#co fieldset.act > label {
  color: #edf1f3;
  top: -15px;
  padding-top: 0;
  font-weight: 800;
  font-size: 12px;
}

#co fieldset.act > textarea {
  color: #000;
  max-height: 250px;
  overflow: visible;
}

#co fieldset.act > input {
  color: #000;
}

#co fieldset + .btfb button {
  top: 10px !important;
}

#co span.svg {
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
}

#co span.svg svg {
  width: 200px;
  height: 200px;
  max-width: 200px;
  margin: auto;
}

#co .cls-1,
.cls-2,
.cls-3 {
  fill: #edf1f3;
  opacity: 0;
}

@keyframes fireworkone {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.15;
  }

  50% {
    opacity: 0.3;
  }

  75% {
    opacity: 0.15;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fireworktwo {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.45;
  }

  50% {
    opacity: 0.8;
  }

  75% {
    opacity: 0.45;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fireworthree {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.55;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.55;
  }

  100% {
    opacity: 0;
  }
}

.firework-icon .cls-1,
.firework-icon .cls-2,
.firework-icon .cls-3 {
  opacity: 0;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.firework-icon .cls-3 {
  animation-name: fireworthree;
  animation-delay: 0.11s;
}

.firework-icon .cls-2 {
  animation-name: fireworktwo;
  animation-delay: 0.41s;
}

.firework-icon .cls-1 {
  animation-name: fireworkone;
  animation-delay: 0.71s;
}

@keyframes elastic-pulse {
  0% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

.img-firework {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: elastic-pulse;
  animation-delay: 0s;
}

#cof {
  padding: 0 calc(20px + 200 * (100vw - 320px) / 1080);
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whte-99 #cof {
  pointer-events: auto;
}

#cof form {
  width: 100%;
  padding: calc(25px + 10 * (100vw - 320px) / 1080) 0;
  text-align: center;
}

#cof .btf {
  transition: color 0.3s, background 0.3s, transform 0.3s;
  z-index: 100;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  width: calc(40px + 10 * (100vw - 320px) / 1080);
  height: calc(40px + 10 * (100vw - 320px) / 1080);
  font-size: calc(15px + 5 * (100vw - 320px) / 1080);
}

html:not(.mob) #cof .btf:hover {
  background-color: #edf1f3;
}

#cof .btf:active {
  transform: scale(0.8);
}

#cof .times {
  top: -100px;
  opacity: 0;
  margin-bottom: calc(15px + 1%);
}

#cof .times:hover {
  transform: rotate(-90deg);
}

#cof .times:active {
  transform: scale(0.8) rotate(-90deg);
}

#cof .btfb {
  display: flex;
  justify-content: center;
  margin-top: calc(15px + 1%);
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(100% + 15px));
}

#cof .btfb .btf {
  margin: 0 5px;
}

#cof .tit {
  font-weight: 800;
  font-size: calc(18px + 18 * (100vw - 320px) / 1080);
  overflow: hidden;
  line-height: 1.2;
}

#cof .tit > div:not(.t1) {
  position: absolute;
  transform: translateY(100%);
  top: 0;
  width: 100%;
}

#cof .fc {
  margin-top: calc(20px + 1%);
}

#cof .fm1 {
  z-index: 1;
}

#cof .fm1 > div {
  transition: height 1s !important;
}

#cof .fc > div:not(.fm1) {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#cof .fm2 {
  z-index: 0;
}

#cof .slc {
  margin: 0 -5px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#cof .slc li {
  padding: 5px;
}

#cof .slc3 li {
  width: 33.33%;
  max-width: 33.33%;
  flex-basis: 33.33%;
}

#cof .slc li > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 50px transparent;
  transition: all 0.4s, transform 0.2s;
  padding: calc(25px + 10 * (100vw - 320px) / 1080) calc(20px + 10 * (100vw - 320px) / 1080);
  cursor: pointer;
  border-radius: 30px;
}

html:not(.mob) #cof .slc li > div:hover {
  background-color: #edf1f3;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
}

#cof .slc li > div:active {
  transform: scale(0.9);
}

#cof .slc i {
  opacity: 0.7;
  transition: transform 0.4s;
  display: inline-block;
  font-size: calc(45px + 10 * (100vw - 320px) / 1080);
}

#cof .slc li > div:hover i {
  transform: scale(1.2) rotate(-5deg);
}

#cof .slc .t {
  font-size: calc(16px + 3 * (100vw - 320px) / 1080);
  font-weight: 700;
}

#cof .slc i + .t {
  margin-top: calc(15px + 5 * (100vw - 320px) / 1080);
}

[data-range] {
  width: 30%;
  margin: calc(15px + 0.5%) auto calc(35px + 1%);
  height: 3px;
}

[data-range]:before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: calc(100% + 20px);
  height: 100%;
  z-index: 0;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

[data-range] .ui-slider-range {
  opacity: 0.5;
  cursor: pointer;
  height: 100%;
  background-color: #16141c;
  border-radius: 25px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
}

[data-range] .ui-slider-handle {
  cursor: grab;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-range] .value {
  background-color: #edf1f3;
  transition: 0.1s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center bottom;
  font-size: 10px;
  padding-top: 15px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.05);
}

[data-range] .both-max:before {
  opacity: 0;
  transition: 0.2s;
  width: 100%;
  text-align: center;
  content: 'ou +';
  position: absolute;
  bottom: 9px;
  left: 0;
  font-size: 9px;
}

[data-range].maxi .both-max:before {
  opacity: 1;
}

[data-range] .ui-state-active {
  cursor: grabbing;
  z-index: 9999;
  bottom: 100%;
}

[data-range] .ui-state-active .value {
  transform: translate(-50%, -25%) scale(1.5);
}

#et {
  padding: calc(30px + 10%) 0;
  overflow: hidden;
  z-index: 4000;
}

#et .swp {
  margin: 0 calc(-3px - 30 * (100vw - 320px) / 1080);
}

#et .tc {
  left: -10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  background-color: #edf1f3;
  box-sizing: content-box;
  border: 5px solid #edf1f3;
}

#et .swiper-wrapper {
  cursor: grab;
}

#et.premove .swiper-wrapper {
  cursor: grabbing;
}

#et .sw {
  height: calc(300px + 300 * (100vw - 320px) / 1080);
  max-height: 70vh;
  padding: 0 calc(6px + 60 * (100vw - 320px) / 1080);
  cursor: grab;
}

#et .cont {
  width: 100%;
  height: 100%;
  background-color: #edf1f3;
}

#et .ct h3 {
  margin-bottom: calc(35px + 10 * (100vw - 320px) / 1080);
  font-size: calc(24px + 6 * (100vw - 320px) / 1080);
  font-weight: 600;
}

#et .bg {
  overflow: hidden;
}

#et.move .bg {
  transform: scale(0.9);
}

#et .bg:after {
  opacity: 0.5;
  content: '';
  background-color: #16141c;
}

#et.move .bg:after {
  opacity: 0;
}

#et .bg,
#et .bg > div,
#et .bg:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#et .bg > div {
  height: 150%;
}

#et.move .bg > div {
  transform: scale(1.1);
}

#et .txt {
  user-select: none;
  -moz-user-select: none;
  transition: opacity 0.8s !important;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

#et.move .txt {
  opacity: 0.2;
}

#et .nm {
  white-space: nowrap;
  font-size: calc(35px + 65 * (100vw - 320px) / 1080);
  text-align: center;
  font-weight: 800;
  color: #edf1f3;
  z-index: 10;
  line-height: 0.9;
}

#et .txt ul {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1080);
  color: #edf1f3;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: calc(10px + 4 * (100vw - 320px) / 1080);
  font-weight: 400;
}

#et .txt ul li:not(:last-child):after {
  vertical-align: middle;
  margin: 0 7px;
  top: -1px;
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: #edf1f3;
  border-radius: 50%;
}

#f {
  overflow: hidden;
  font-size: calc(12px + 2 * (100vw - 320px) / 1080);
  color: #5c5c5c;
  line-height: 1.5;
  padding: calc(30px + 3%) 0 calc(25px + 3%);
  font-weight: 700;
}

#f .clo {
  display: flex;
  margin-top: calc(15px + 1%);
}

#f .clo button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#f .clo button + button {
  margin-left: 8px;
}

#f .clo button:before {
  transition: 0.2s;
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 26px;
  height: 26px;
  z-index: 0;
  border: 1px solid #edf1f3;
  border-radius: 50%;
  opacity: 0;
}

#f .clo button:hover:before {
  opacity: 0.2;
}

#f .clo button.select:before {
  opacity: 1;
}

#f .ct {
  display: flex;
}

#f .l,
#f .lm {
  padding-right: calc(25px + 7%);
}

#f .r {
  margin-left: auto;
}

#f .logo {
  margin-bottom: calc(20px + 1%);
  font-size: 20px;
  font-weight: 600;
  fill: #edf1f3;
}

#f h4 {
  margin-top: 3px;
  font-size: calc(13px + 3 * (100vw - 320px) / 1080);
  margin-bottom: calc(10px + 1%);
}

#f .inf {
  font-size: calc(13px + 3 * (100vw - 320px) / 1080);
  line-height: 1.3;
  font-weight: 800;
}

#f .inf > * {
  cursor: pointer;
  transition: color 0.3s;
}

#f .rs {
  margin-bottom: calc(20px + 1%);
  display: flex;
  justify-content: space-between;
  font-size: calc(18px + 2 * (100vw - 320px) / 1080);
}

#f .rs li + li {
  margin-left: calc(10px + 15 * (100vw - 320px) / 1080);
}

#f .rs i {
  font-weight: 300;
}

#f .r {
  text-align: right;
}

#f .r a:hover {
  color: #edf1f3;
}

#f .ctbot {
  margin: calc(30px + 3%) 0 calc(-25px - 3%);
  font-size: 10px;
}

fieldset {
  text-align: left;
  border: 0;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  height: 45px;
  font-size: 14px;
}

fieldset ul {
  background: #ff4e4e;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 50%;
  padding: 0;
}

fieldset ul:before {
  font-family: 'font awesome 5 pro';
  content: '\f00d';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

fieldset ul li {
  display: none;
}

fieldset + fieldset {
  margin-top: 13px;
}

fieldset > * {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0;
  padding: 16px 25px;
  resize: none;
}

fieldset > label {
  transition: 0.2s;
  color: #6d6681;
}

fieldset.act > label {
  height: 15px;
  padding-top: 0;
  font-size: 10px;
  top: -4px;
}

fieldset.txt {
  height: auto;
  min-height: 45px;
}

fieldset textarea {
  position: relative;
  overflow: hidden;
}

.tp {
  overflow: hidden;
  text-align: center;
  padding-top: calc(80px + 4%);
  background: linear-gradient(120deg, #27ae60, #00947e);
  color: #edf1f3;
}

.tp .ct {
  z-index: 5;
  padding: calc(50px + 2%) 0;
}

.tp .sub {
  margin-bottom: calc(15px + 5 * (100vw - 320px) / 1080);
  font-size: calc(11px + 1 * (100vw - 320px) / 1080);
  text-transform: uppercase;
  letter-spacing: calc(5px + 6 * (100vw - 320px) / 1080);
}

.tp .h1 {
  font-weight: 800;
  font-size: calc(20px + 20 * (100vw - 320px) / 1080);
  margin-bottom: calc(5px + 10 * (100vw - 320px) / 1080);
}

.tp .bg {
  opacity: 0.08;
  filter: blur(5px) grayscale(1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#err {
  height: 100vh;
  color: #edf1f3;
  display: flex;
  align-items: center;
}

#err .smile {
  font-weight: 800;
  font-size: calc(50px + 15 * (100vw - 320px) / 1080);
}

#err .error-code {
  font-weight: 800;
  font-size: calc(18px + 8 * (100vw - 320px) / 1080);
  margin: calc(25px + 2%) 0 calc(10px + 0.5%);
}

#err p {
  line-height: 1.3;
  font-size: calc(13px + 3 * (100vw - 320px) / 1080);
  margin-bottom: calc(25px + 2%);
}

.def h2 {
  font-size: calc(17px + 7 * (100vw - 320px) / 1080);
}

.def h3 {
  font-size: calc(15px + 5 * (100vw - 320px) / 1080);
  font-weight: 400;
}

.def h4 {
  font-size: calc(14px + 5 * (100vw - 320px) / 1080);
  font-weight: 800;
}

.def * + figure,
.def * + h2,
.def * + h3 {
  margin-top: calc(30px + 5 * (100vw - 320px) / 1080);
}

.def * + h4 {
  margin-top: calc(25px + 5 * (100vw - 320px) / 1080);
}

.def * + .p {
  margin-top: calc(20px + 5 * (100vw - 320px) / 1080);
}

.def .p {
  font-weight: 400;
  font-size: calc(13px + 1 * (100vw - 320px) / 1080);
  line-height: 1.6;
  color: #3f3b48;
}

.def .p strong {
  display: inline;
}

.def .p > * + * {
  margin-top: calc(15px + 5 * (100vw - 320px) / 1080);
}

.def .p ul li {
  padding-left: 15px;
}

.def .p ul li + li {
  margin-top: 10px;
}

.def .p ul li:before {
  content: 'â€¢';
  position: absolute;
  left: 0;
}

.shre {
  margin-top: calc(25px + 2%);
  padding-top: calc(20px + 0.5%);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  font-weight: 800;
  font-size: calc(13px + 3 * (100vw - 320px) / 1080);
}

.shre ul {
  margin-left: 15px;
  font-weight: 400;
  font-size: calc(15px + 3 * (100vw - 320px) / 1080);
  display: flex;
  align-items: center;
}

.shre ul li + li {
  margin-left: 10px;
}

.hspan span {
  display: block;
  white-space: nowrap;
}

.hspan > span {
  line-height: 1.2;
  overflow: hidden;
  margin-bottom: calc(0px - 5 * (100vw - 320px) / 1080);
}

.cn-thx .rs {
  display: flex;
  justify-content: center;
  margin: 15px 0 0;
}

.cn-thx .rs li {
  margin: 0 5px;
}

@media screen and (max-width: 1600px) {
  #f .ct {
    width: 90vw;
  }

  #f .l,
  #f .lm {
    padding-right: calc(20px + 5%);
  }
}

@media screen and (max-width: 1250px) {
  #sv .rg {
    padding-left: 75%;
  }

  #sv .lgt {
    padding-right: 35%;
  }
}

@media screen and (max-width: 1084px) {
  .ct,
  #we .wec {
    width: 85vw;
  }

  #f .ct {
    flex-wrap: wrap;
  }

  #f .l {
    order: 10;
    padding-top: calc(15px + 1%);
    margin-top: calc(15px + 1%);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
  }

  #f .logo {
    margin-bottom: 0;
  }

  #f .l br {
    display: none;
  }

  #f .clo {
    margin-top: 0;
  }

  .afar {
    display: block;
  }

  .afar .left {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    padding-right: 0;
  }

  .afar .asi {
    padding-left: 0;
    border-left: 0;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .afar .asi h3 {
    font-size: calc(17px + 8 * (100vw - 320px) / 1080);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    margin-top: calc(25px + 2%);
    padding-top: calc(15px + 1%);
  }

  .afar .asi .lasc h4 {
    font-size: calc(16px + 5 * (100vw - 320px) / 1080);
  }

  .afar .asi .gct {
    display: flex;
    align-items: center;
    margin: -7px;
  }

  .afar .asi .gct > * {
    margin-top: 0;
    margin: 7px;
  }

  #crea .cre {
    display: flex;
    flex-direction: column;
  }

  #crea .cre .l {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: calc(40px + 10 * (100vw - 320px) / 1080);
  }

  #crea .cre .r {
    width: 100%;
    position: relative;
  }

  #crea .cre .bca {
    display: inline-block;
    margin-right: 25px;
  }

  .line-p span {
    font-size: 9px;
  }
}

@media screen and (min-width: 901px) {
  #sv .rg > div {
    transform: none !important;
  }
}

@media screen and (max-width: 950px) {
  html:not(.horiz) #app .app-c.appet {
    overflow: visible;
  }

  .ec {
    display: block;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .ec > *:not(.cov) {
    height: auto;
  }

  .ec section:not(.cov) {
    display: block;
  }

  .ec section > .ct,
  .ec .pres .ct,
  .ec .part-2 .ct {
    padding: calc(45px + 50 * (100vw - 320px) / 1080) 0;
    width: 85vw;
    margin: auto;
  }

  .ec .cov .tc {
    left: 50%;
    transform: translateX(-50%);
  }

  .ec .tc i.awr {
    animation: arrowd 1s infinite linear;
  }

  .ec .tc i:before {
    display: block;
    transform: rotate(90deg);
  }

  .ec .pres .info > div {
    width: 50%;
  }

  .ec .mck {
    margin: 5vh auto;
  }

  .ec .mck-5 {
    margin-bottom: -3vh;
  }

  .ec .mck img {
    max-height: auto;
    max-width: 100%;
  }

  .ec .mck.mck-1 {
    left: -10vw;
  }

  .ec .mck.mck-3 {
    margin-top: -15vw;
  }

  .ec .bga {
    width: 100%;
    height: calc(350px + 250 * (100vw - 320px) / 1080);
  }

  .ec .bga > div {
    width: 100%;
    height: 150%;
  }

  .ec .desc > div {
    width: 50%;
  }

  .ec .tch {
    left: 50%;
    top: 0;
  }

  .ec .tch + .ct {
    padding: calc(70px + 20 * (100vw - 320px) / 1080) 0;
  }

  .line-ec {
    height: 2px;
  }

  .ec .part-lg .ct {
    display: block;
  }

  .ec .part-lg .lgs {
    flex-direction: column;
    margin: calc(30px + 3%) auto calc(50px + 200 * (100vw - 320px) / 1080);
    margin-left: 0;
  }

  .ec .part-lg .lgs img {
    width: calc(350px + 200 * (100vw - 320px) / 1080);
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .ec .part-lg .lgs img + img {
    margin-top: calc(40px + 3%);
  }

  .ec .resul .chif {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .ec .resul .chif > div {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    margin-right: 0;
    margin-top: calc(15px + 2%);
  }

  .ec .resul .nmb {
    margin: 0 calc(15px + 1%) 0 0;
  }

  .ec section.nxt {
    width: 100%;
    height: 100vh;
    display: flex;
  }

  #sv .rg {
    padding-left: 0 !important;
    left: 0;
  }

  #sv .rg > div {
    transform: translateY(-70%) rotate(-90deg);
    transform-origin: center center;
  }

  #sv .rg .cir {
    top: 0;
    width: 100vw;
    height: 100vw;
  }

  #sv .rg .rnd {
    transform: rotate(90deg);
  }

  #sv .lgt {
    padding: 30vw 0 0 !important;
    text-align: center;
  }

  #sv .lgt .tx {
    width: 100%;
    padding: 0 calc(20px + 5%);
    margin: calc(25px + 15 * (100vw - 320px) / 1080) 0;
  }

  #sv .crc:nth-child(1),
  #sv .crc:nth-child(3) {
    width: 50vw;
  }

  #sv .crc:nth-child(2),
  #sv .crc:nth-child(4) {
    height: 50vw;
  }

  #sv .lgt h3 strong {
    font-weight: 800;
    display: block;
    font-size: 200%;
  }

  .eavl {
    text-align: center;
    margin-top: 0 !important;
    position: relative;
    align-items: flex-end;
  }

  .eavl h2 {
    display: none;
    transform: none !important;
    opacity: 1 !important;
    width: 100%;
    top: 100vh;
    left: 0;
  }

  .eavl .ct {
    width: 100%;
    text-align: center;
  }

  .lgtxc .ct > div {
    width: 100%;
    left: 0;
    text-align: center;
  }

  .cirbc svg,
  .cirbc .ico {
    top: 30%;
  }
}

@media screen and (max-width: 900px) {
  #n .nr {
    display: none;
  }

  #n .nl {
    width: 100%;
    text-align: center;
  }

  #n .nl .nlc {
    padding: 0 calc(20px + 3%);
    font-size: calc(25px + 30 * (100vw - 320px) / 1080);
  }

  #n .nl nav li:nth-child(4) {
    display: block;
  }

  #h .brf i {
    display: none !important;
  }

  #cof {
    padding: 0 calc(10px + 60 * (100vw - 320px) / 1080);
  }
}

@media screen and (min-width: 851px) {
  .lst > *.big {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .lst .big > * {
    display: flex;
  }

  .lst .big .lf {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    padding-right: 25px;
    margin-bottom: 0;
  }

  .lst .big .rg {
    align-self: center;
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    padding-left: 25px;
  }

  .lst .big h2 {
    font-size: calc(24px + 15 * (100vw - 320px) / 1080);
  }
}

@media screen and (max-width: 850px) {
  .lst > * {
    padding: calc(10px + 15 * (100vw - 320px) / 1080) 20px;
  }

  #crea .cre .l {
    padding-top: calc(90px + 30 * (100vw - 320px) / 1080);
  }

  #crea .cre .l .lct .dte {
    margin-bottom: 0;
  }

  #crea .cre .l .lct .p {
    display: none;
  }

  #cn {
    width: calc(400px + 200 * (100vw - 320px) / 1080) !important;
    margin: 40vh auto 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  #f .ct {
    width: 85vw;
  }

  #f .ct > * {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  #f .rm,
  #f .lm {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
  }

  #f h4 {
    margin-bottom: 0;
  }

  #f .inf {
    font-size: calc(15px + 4 * (100vw - 320px) / 1080);
  }

  #f .inf br {
    display: none;
  }

  /* #f .tl:after {
        content: '-';
        margin-left: 5px;
        pointer-events: none;
        color: #edf1f3 !important
    } */

  #f .r {
    padding-top: calc(15px + 1%);
    margin-top: calc(15px + 1%);
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;
  }

  #f .rs {
    margin-bottom: 0;
  }

  #sc {
    text-align: center;
  }

  #sc .tc {
    margin: auto;
  }

  #sc .ls li {
    margin-top: calc(40px + 10 * (100vw - 320px) / 1080);
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  #sc .ls .ic {
    margin: auto;
    width: calc(100px + 50 * (100vw - 320px) / 1080);
    height: calc(100px + 50 * (100vw - 320px) / 1080);
  }

  #sc .ic svg {
    stroke-width: 2px;
    opacity: 0;
  }

  #sc i {
    font-size: calc(25px + 15 * (100vw - 320px) / 1080);
  }

  #sc .ls.lsl > li {
    display: block;
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    margin-top: calc(40px + 10 * (100vw - 320px) / 1080);
  }

  #sc .ls.lsl .ic {
    margin-right: auto;
    margin-bottom: calc(20px + 5 * (100vw - 320px) / 1080);
  }

  #sc .ls .p {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .nvsw {
    justify-content: center;
  }
}

@media screen and (max-width: 780px) {
  #et .tc {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 750px) {
  #f .rm,
  #f .lm {
    margin: 0;
    display: block;
    text-align: center;
  }

  #f .lm + .lm,
  #f .rm {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  #f .r {
    padding-top: 25px;
  }

  #f h4 {
    margin-bottom: 5px;
  }

  #f .inf {
    font-size: calc(12px + 10 * (100vw - 320px) / 1080);
  }

  #f .l {
    flex-direction: column;
    padding-top: 25px;
  }

  #f .clo {
    margin-top: 15px;
  }

  .lst {
    margin-top: 5px;
  }

  .lst > * {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  #a .gcat a i {
    font-size: calc(50px + 20 * (100vw - 320px) / 1080);
    margin-bottom: 0;
  }

  #a .gcat a span {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .ec .resul .chif > div {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .ec .pres .txt {
    font-size: calc(13px + 2 * (100vw - 320px) / 1080);
  }

  .ec .sb {
    font-size: 10px;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 680px) {
  .afar .asi .gct {
    flex-wrap: wrap;
  }

  .afar .asi .gct > * {
    padding: calc(30px + 10 * (100vw - 320px) / 1080) 15px;
  }

  .afar .asi .gct a i {
    font-size: calc(30px + 5 * (100vw - 320px) / 1080);
  }

  #we {
    align-items: flex-end;
  }

  #sc h2 {
    font-size: calc(20px + 10 * (100vw - 320px) / 1080);
  }
}

@media screen and (max-width: 600px) {
  #cof .slc li {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }

  #cof .slc li > div {
    padding: calc(20px + 10 * (100vw - 320px) / 1080) calc(15px + 10 * (100vw - 320px) / 1080);
  }

  #cof .slc li i {
    font-size: calc(40px + 10 * (100vw - 320px) / 1080);
  }

  #cof .slc .t {
    font-size: calc(14px + 5 * (100vw - 320px) / 1080);
  }

  .swe .nme {
    font-size: calc(30px + 15 * (100vw - 320px) / 1080);
  }

  #eu .eui {
    height: calc(400px + 200 * (100vw - 320px) / 1080);
  }

  .ec .desc {
    flex-wrap: wrap;
  }

  .ec .desc > * {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .ec .desc > * + * {
    margin-top: calc(25px + 2%);
  }
}

@media screen and (max-width: 550px) {
  #sc .ls.lsl > li {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  #h .brf {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  #cof .slc li > div {
    padding: calc(15px + 8 * (100vw - 320px) / 1080) calc(10px + 8 * (100vw - 320px) / 1080);
  }

  #cof .slc li i {
    font-size: calc(35px + 8 * (100vw - 320px) / 1080);
  }

  #cof .slc .t {
    font-size: calc(13px + 4 * (100vw - 320px) / 1080);
  }
}

/* Color */
*::-moz-selection {
  background: #f9224b;
  color: #fff;
}

*::selection {
  background: #f9224b;
  color: #fff;
}

.c-primary {
  color: #27ae60;
}

.c-secondary {
  color: #f9224b;
}

.c-white {
  color: #fff;
}

.c-black {
  color: #111;
}

.color_bg,
.color_bg_af:after,
#ld .bg,
#cl .bg > div,
#n .nl .bg,
.scrollbar-track-y .scrollbar-thumb:before,
#co,
#we .smc .smc_s-tit,
#we .smc .smc_lg > div,
#we .smc .fi,
#sv .rnd > div > div,
.tag:after,
#a .r button:after,
.ltml,
#map .marker,
.ec .list-s i:after,
.swe .bg,
#ck {
  background: linear-gradient(360deg, #27ae60, #00947e) !important;
}

.drg,
#sc .ls h4 strong,
.afar .gct a small:before,
#a .gcat a small:before,
#e .lgtxt h3 span,
#e .lgtxt h2 strong,
.ec .resul .tit small,
.afar .p a:not([data-editor-btn]),
#sc .p a:not([data-editor-btn]):not(.btn),
.def .p ul li:before {
  background: linear-gradient(to right, #27ae60, #00947e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#wr .wrl .line,
.btn:before,
.line-ec .ln {
  background: linear-gradient(to right, #27ae60, #009b4d);
}

.bn svg {
  stroke: #009b4d;
}

.swe .fab:hover,
.ec .nxt a:hover .tc,
.ec [data-go]:hover,
.line-ec .lp > div:hover,
.shre a:hover,
#n .nr a:hover,
#n .nr .art li:hover a,
html.ie #we .wem .wew > div,
[data-range] .value,
.color_h:hover,
#wr .ckb:hover,
.c1,
#f .inf > *:hover,
#f a:hover,
html:not(.mob) #cof .slc li > div:hover,
#cof .btf:hover,
.tag:hover {
  color: #f9224b;
}

#wr input:checked + .ckb,
#we .smc .smc_ul span > span {
  border-color: #f9224b;
}

#map .mapboxgl-ctrl-group > button:hover,
.eth [data-cursor] .cur2,
.tInf time:after {
  background: #f9224b;
}

/************* service **************/
#sc {
  z-index: 100;
  padding: calc(60px + 80 * (100vw - 320px) / 1080) 0;
  font-weight: 200;
  /* background: linear-gradient(120deg, #27ae60, #00947e); */
  color: #333;
}

#sc h2 {
  margin-top: calc(25px + 2%);
  font-size: calc(30px + 20 * (100vw - 320px) / 1080);
  font-weight: 800;
}

#sc h2 strong {
  font-weight: inherit;
}

#sc .pp {
  padding-top: 15px;
  width: calc(900px + 100 * (100vw - 320px) / 1080);
  max-width: 100%;
  font-weight: 400;
  line-height: 1.5;
}

#sc .pp > * {
  margin: calc(10px + 1.5%) 0 calc(10px + 1%);
}

#sc .pp a {
  font-weight: 800;
}

#sc .intro {
  font-size: calc(14px + 5 * (100vw - 320px) / 1080);
}

#sc .tc svg {
  fill: #edf1f3;
}

#sc .tc i {
  color: #edf1f3;
}

#sc .ls {
  display: flex;
  flex-wrap: wrap;
}

#sc .ls + h3 {
  margin-top: calc(45px + 20 * (100vw - 320px) / 1080);
}

#sc .ls li {
  margin-top: calc(30px + 20 * (100vw - 320px) / 1080);
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

#sc .ls.lsl li {
  display: flex;
  align-items: center;
}

#sc .ic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(70px + 30 * (100vw - 320px) / 1080);
  height: calc(70px + 30 * (100vw - 320px) / 1080);
}

#sc .lsl .ic {
  margin-right: calc(20px + 10 * (100vw - 320px) / 1080);
}

#sc .ic svg {
  position: absolute;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
  fill: transparent;
  stroke-width: 2px;
  opacity: 0;
}

#sc .ls i {
  font-size: calc(35px + 10 * (100vw - 320px) / 1080);
  transform: translateY(5%);
}

#sc .ls .bta {
  margin-top: calc(20px + 5 * (100vw - 320px) / 1080);
}

#sc h3 {
  font-weight: 800;
  margin-top: calc(35px + 10 * (100vw - 320px) / 1080);
  font-size: calc(17px + 6 * (100vw - 320px) / 1080);
}

#sc h4 {
  font-weight: 800;
  margin-top: calc(30px + 10 * (100vw - 320px) / 1080);
  font-size: calc(15px + 5 * (100vw - 320px) / 1080);
}

#sc h3 + .p,
#sc h4 + .p {
  margin-top: 0;
}

#sc h3 + .p.pbr {
  margin-top: 15px !important;
}

#sc .lsl + h3 {
  margin-top: calc(50px + 10 * (100vw - 320px) / 1080);
}

#sc .ls h4 {
  margin-top: calc(20px + 5 * (100vw - 320px) / 1080);
  font-size: calc(15px + 5 * (100vw - 320px) / 1080);
  font-weight: 200;
}

#sc .ls.lsl h4 {
  margin-top: 0;
}

#sc .ls h4 span {
  display: block;
  margin-bottom: 5px;
}

#sc .ls h4 strong {
  display: block;
  font-size: 130%;
  font-weight: 800;
}

#sc .ls .p {
  width: 80%;
  font-size: calc(12px + 1 * (100vw - 320px) / 1080);
  line-height: 1.5;
  margin-top: calc(10px + 1%);
}

#sc .grd {
  margin: calc(30px + 20 * (100vw - 320px) / 1080) -10px -10px;
}

#sc .grd > div {
  position: absolute;
  padding: 10px;
}

#sc .grd img {
  width: 100%;
  left: 0;
}

#sc .ih3 {
  margin-right: 5px;
}

/************* modal **************/
#crea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: none;
  color: #edf1f3;
}

.creaOpen #crea {
  pointer-events: auto;
}

#crea .cre {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#crea .cre.open {
  pointer-events: auto;
}

#crea .cre .l {
  padding: calc(120px + 30 * (100vw - 320px) / 1080) calc(20px + 3%) 0;
  width: 30%;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 50;
}

#crea .cre .l .lct {
  z-index: 10;
}

#crea .cre .l .lct > * {
  transform: translateY(100);
  opacity: 0;
}

#crea .cre .l .lct h6 {
  font-size: calc(16px + 5 * (100vw - 320px) / 1080);
  font-weight: 800;
}

#crea .cre .l .lct .dte {
  font-size: calc(11px + 1 * (100vw - 320px) / 1080);
  margin: 15px 0 25px;
  font-weight: bold;
}

#crea .cre .l .lct .p {
  padding-bottom: 5px;
  font-size: calc(12px + 2 * (100vw - 320px) / 1080);
  font-weight: 200;
  line-height: 1.4;
}

#crea .cre .l .bg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  background: linear-gradient(120deg, #27ae60, #00947e);
}

#crea .cre .r {
  user-select: none;
  -moz-user-select: none;
  /* width: 70%; */
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
}

#crea .cre .r .bg {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  /* bottom: 0; */
  height: 0;
  color: #edf1f3;
  background: linear-gradient(120deg, #27ae60, #00947e);
}

#crea .cre .r .rct {
  z-index: 10;
  padding: calc(60px + 30 * (100vw - 320px) / 1080) 0 calc(30px + 30 * (100vw - 320px) / 1080);
}

#crea .cre .r .rct > * {
  transform: translateY(100);
  opacity: 0;
}

#crea .cre .r .rct h6 {
  margin-bottom: 24px;
  font-size: calc(20px + 5 * (100vw - 320px) / 1080);
  font-weight: 600;
}

#crea .cre .r .rct .dte {
  font-size: calc(12px + 2 * (100vw - 320px) / 1080);
  margin-bottom: 14px;
  font-weight: 400;
}

#crea .cre .r .rct .dte em {
  display: inline-block;
  width: 100px;
  font-style: normal;
}

#crea .cre .r .rct .p {
  padding-bottom: 5px;
  font-size: calc(12px + 2 * (100vw - 320px) / 1080);
  font-weight: 400;
  line-height: 1.4;
}

#crea .cre .r .rc {
  padding: calc(30px + 60 * (100vw - 320px) / 1080);
  z-index: 500;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

#crea .cre .r .rc .scroll-content {
  padding-bottom: calc(100px + 60 * (100vw - 320px) / 1080);
}

#crea .cre .r .rc img {
  max-width: 100%;
  transform: translateY(100);
  opacity: 0;
  user-select: none;
  -moz-user-select: none;
}

#crea .cre .r .rc img + img {
  margin-top: calc(20px + 2%);
}

#crea .cre .bca {
  margin-top: 30px;
}

/************* service **************/
#eu {
  z-index: 1000;
}

#eu > section {
  height: 100vh;
}

#eu .ct {
  /* width: 100vw; */
}

#eu .service-ct {
  width: 100vw;
}

#eu .tit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#eu .tit .h1,
#eu .tit .hspan {
  font-size: calc(25px + 15 * (100vw - 320px) / 1080);
}

#eu .tit .sub {
  margin: calc(30px + 15 * (100vw - 320px) / 1080) 0 calc(20px + 1%);
  font-size: calc(11px + 2 * (100vw - 320px) / 1080);
  text-transform: uppercase;
  letter-spacing: calc(2px + 1 * (100vw - 320px) / 1080);
}

#eu .tit .sub h1 {
  font-weight: 300;
}

#eu .tit .tc {
  margin: auto;
  margin-top: calc(30px + 15 * (100vw - 320px) / 1080);
}

#eu .eui {
  color: #edf1f3;
  width: 100%;
  height: calc(350px + 200 * (100vw - 320px) / 1080);
  background-color: #000;
  overflow: hidden;
}

#eu .eui .bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 150%;
  left: 0;
  opacity: 0.8;
}

#eu .eui .txt {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#eu .eui .nm {
  white-space: nowrap;
  font-size: calc(35px + 65 * (100vw - 320px) / 1080);
  text-align: center;
  font-weight: 800;
  color: #edf1f3;
  z-index: 10;
  line-height: 0.9;
}

#eu .eui .txt ul {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1080);
  color: #edf1f3;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  font-size: calc(11px + 3 * (100vw - 320px) / 1080);
  font-weight: 400;
  padding: 0 20px;
}

#eu .eui .txt ul li:not(:last-child):after {
  vertical-align: middle;
  margin: 0 7px;
  top: -1px;
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: #edf1f3;
  border-radius: 50%;
}

#eu .grd {
  margin: calc(30px + 20 * (100vw - 320px) / 1080) 0 0;
}

#eu .grd > div {
  position: absolute;
}

.grd figure {
  display: block;
}

.grd figure > img {
  left: 0;
  width: 100%;
}

.grd figcaption {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: opacity 0.3s linear, -webkit-transform 0.3s ease;
  transition: opacity 0.3s linear, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, opacity 0.3s linear;
  transition: transform 0.3s ease, opacity 0.3s linear, -webkit-transform 0.3s ease;
}

.grd figure:hover figcaption {
  opacity: 1;
}

.grd [data-project-id] {
  position: relative;
  overflow: hidden;
  /* height: 350px; */
}

.grd [data-project-id] .tit {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 10;
  font-size: 38px;
  font-weight: 600;
  color: #fff;
}

.grd [data-project-id] > img {
  width: 100%;
  height: 100%;
  -webkit-object-fit: cover;
  object-fit: cover;
  -webkit-animation: scaleDown 1s forwards;
  animation: scaleDown 1s forwards;
}

.grd [data-project-id]:hover > img {
  -webkit-animation: scaleUp 1s forwards;
  animation: scaleUp 1s forwards;
}

.menu-link {
}

.menu-link-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
}

.menu-link-box:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc(-1px - 1.5 * (100vw - 320px) / 1080);
  z-index: 10;
  width: 0;
  height: 2px;
  background: transparent;
  transition: 0.5s;
}

.menu-link:hover .menu-link-box:after {
  width: 100%;
  background: linear-gradient(to left, #f9224b, #ed213a);
}

.menu-link-box .text-sm {
  margin-right: 1.5rem;
  font-size: calc(16px + 2 * (100vw - 320px) / 1080);
  font-weight: 400;
  text-align: left;
}

.menu-link-box .text-lg {
  font-size: calc(18px + 10 * (100vw - 320px) / 1080);
  font-weight: 500;
  text-align: left;
  word-break: keep-all;
}

.menu-link img {
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0;
  width: 2rem;
  height: 2rem;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: 0.5s;
}

.menu-link:hover img {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
}

.grd [data-project-id] .tit {
  font-size: 38px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .grd [data-project-id] .tit {
    bottom: 20px;
    left: 20px;
    font-size: 2.63888889vw;
  }
  .menu-link-box {
    padding-right: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .grd [data-project-id] .tit {
    bottom: 4.11111vw;
    left: 4.11111vw;
    font-size: 4.44444444vw;
  }
  .menu-link-box {
    padding-right: 2rem;
  }
  .menu-link-box .text-sm {
    margin-right: 0.5rem;
  }
}

@-webkit-keyframes scaleUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.13);
    transform: scale(1.13);
  }
}

@keyframes scaleUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.13);
    transform: scale(1.13);
  }
}

@-webkit-keyframes scaleDown {
  0% {
    -webkit-transform: scale(1.13);
    transform: scale(1.13);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleDown {
  0% {
    -webkit-transform: scale(1.13);
    transform: scale(1.13);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/************* service show **************/
.horiz #app {
  left: 0;
  font-weight: 300;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.ec .ct {
  width: auto;
}

.ec {
  top: 0;
  left: 0;
  min-width: 100%;
  display: -webkit-box;
  display: flex;
  z-index: 1;
  min-height: 100%;
  position: absolute;
}

.ec:before {
  background-color: #edf1f3;
  width: calc(100% - 100vw);
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  content: '';
}

.ec > * {
  height: 100vh;
}

.ec [data-go] {
  cursor: pointer;
}

.ec section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.ec section > .ct {
  padding: 0 12vw;
}

.ec .h2 {
  font-size: calc(20px + 15 * (100vw - 320px) / 1080);
  font-weight: 200;
}

.ec .tc + .h2 {
  margin-top: calc(30px + 10 * (100vw - 320px) / 1080);
}

.ec .cov {
  text-align: center;
  color: #edf1f3;
  overflow: hidden;
  background: linear-gradient(120deg, #27ae60, #00947e);
}

.ec .cov .ct {
  min-width: 100vw;
}

.ec .cov .sub {
  margin-bottom: calc(20px + 5 * (100vw - 320px) / 1080);
  font-size: calc(11px + 3 * (100vw - 320px) / 1080);
  text-transform: uppercase;
  letter-spacing: calc(2px + 1 * (100vw - 320px) / 1080);
}

.ec .cov .sub h1 {
  font-weight: 300;
}

.ec .cov .ct {
  z-index: 5;
}

.ec .cov .h1 {
  overflow: hidden;
  white-space: nowrap;
  font-size: calc(35px + 65 * (100vw - 320px) / 1080);
  text-align: center;
  font-weight: 800;
  color: #edf1f3;
  z-index: 10;
  line-height: 1.2;
}

.ec .cov .h1 span {
  display: block;
}

.ec .cov .tc {
  transition: bottom 0.4s, opacity 0.4s;
  color: #edf1f3;
  position: absolute;
  bottom: calc(15px + 30 * (100vh - 320px) / 1080);
  right: calc(15px + 30 * (100vh - 320px) / 1080);
  z-index: 10;
}

.ec .cov .tc svg {
  fill: #edf1f3;
  position: absolute;
  z-index: 10;
}

.ec .cov .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center center;
  background-size: cover;
}

.ec .pres .ct {
  width: auto;
}

.ec .pres .hspan {
  margin: 0 0 calc(40px + 100 * (100vh - 320px) / 1080);
  font-size: calc(25px + 30 * (100vw - 320px) / 1080);
  font-weight: 200;
}

.ec .pres .info {
  display: flex;
  align-items: flex-start;
}

.ec .pres .info > div {
  width: 20vw;
}

.ec .sb {
  font-weight: 800;
  margin-bottom: calc(20px + 1%);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.ec .pres .txt {
  line-height: 1.5;
  font-weight: 300;
  font-size: calc(15px + 3 * (100vw - 320px) / 1080);
}

.ec .mck img {
  max-height: 100vh;
}

.ec .mck .im {
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 0;
  left: 0;
}

.ec .mck-1 {
  z-index: 50;
  margin-left: calc(-200px - 100 * (100vw - 320px) / 1080);
}

.ec .mck-3 {
  z-index: 50;
  margin: 0 calc(30px + 20 * (100vw - 320px) / 1080) 0 calc(-100px - 50 * (100vw - 320px) / 1080);
}

.ec .mck-3 .m2 {
  z-index: 50;
}

.ec .bga {
  width: calc(500px + 300 * (100vw - 320px) / 1080);
  overflow: hidden;
}

.ec .bga > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
}

.ec .part-2 .ct {
  width: auto;
}

.ec .p {
  line-height: 1.8;
  font-size: calc(12px + 1 * (100vw - 320px) / 1080);
}

.ec .p > * + * {
  margin-top: calc(15px + 1%);
}

.ec .btec {
  margin-top: calc(25px + 20 * (100vw - 320px) / 1080);
}

.ec .desc {
  margin: calc(30px + 30 * (100vh - 320px) / 1080) calc(-20px - 1.5%) 0;
  display: flex;
}

.ec .desc > div {
  padding: 0 calc(20px + 1.5%);
  width: calc(250px + 150 * (100vw - 320px) / 1080);
}

.ec .list-s {
  white-space: nowrap;
  margin: calc(30px + 50 * (100vh - 320px) / 1080) 0 calc(-5px - 1vh);
  font-size: calc(13px + 2 * (100vw - 320px) / 1080);
}

.ec .list-s > div {
  margin-bottom: calc(5px + 1vh);
}

.ec .list-s > div > div {
  display: inline-flex;
  align-items: flex-start;
}

.ec .list-s i {
  transition: color 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 5 * (100vw - 320px) / 1080);
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  width: calc(40px + 5 * (100vw - 320px) / 1080);
  height: calc(40px + 5 * (100vw - 320px) / 1080);
}

.ec .list-s > div > div:hover i {
  color: #edf1f3;
}

.ec .list-s i:before {
  z-index: 5;
}

.ec .list-s i:after {
  transition: opacity 0.5s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  content: '';
  z-index: 0;
}

.ec .list-s > div > div:hover i:after {
  opacity: 1;
}

.ec .list-s span {
  align-self: center;
}

.line-ec {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  user-select: none;
  -moz-user-select: none;
}

.line-ec .ln {
  height: 5px;
  position: absolute;
  left: 0;
  width: 50%;
  top: -1px;
}

.line-p {
  color: #edf1f3;
  mix-blend-mode: difference;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.line-p > div {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.2);
}

.line-p span {
  transition: 0.2s;
  font-weight: 400;
  position: absolute;
  white-space: nowrap;
  font-size: 10px;
  bottom: 10px;
  transform: translateX(-100%);
}

.line-p > div:hover span {
  font-size: 12px;
  color: #edf1f3;
}

.ec .tch {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: #edf1f3;
  border-radius: 50%;
  padding: 10px;
}

.ec .part-lg .ct {
  display: flex;
  align-items: center;
}

.ec .part-lg .lgs {
  display: flex;
  align-items: center;
  margin-left: 12vw;
}

.ec .part-lg .lgs img {
  width: calc(200px + 100 * (100vw - 320px) / 1080);
  margin-left: calc(40px + 30 * (100vw - 320px) / 1080);
}

.ec .part-lg .lgsp img {
  width: calc(300px + 200 * (100vw - 320px) / 1080);
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.05);
}

.ec .part-lg .lgsp img.imah {
  width: calc(300px + 500 * (100vw - 320px) / 1080);
}

.ec .resul {
  color: #edf1f3;
  background: linear-gradient(120deg, #27ae60, #00947e);
}

.ec .resul .chif {
  display: flex;
  margin-top: calc(25px + 10 * (100vw - 320px) / 1080);
}

.ec .resul .chif > * {
  text-align: center;
  display: flex;
  align-items: center;
  text-align: left;
  margin-right: calc(40px + 15 * (100vw - 320px) / 1080);
}

.ec .resul .tit {
  margin-left: 20px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1080);
  font-weight: bold;
}

.ec .resul .tit small {
  font-size: calc(12px + 1 * (100vw - 320px) / 1080);
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}

.ec .resul svg {
  fill: none;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
  fill: transparent;
  stroke-width: 2px;
  opacity: 0;
}

.ec .resul .nmb {
  margin: auto;
  width: calc(75px + 15 * (100vw - 320px) / 1080);
}

.ec .resul .nmb strong {
  font-size: calc(16px + 3 * (100vw - 320px) / 1080);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  text-align: center;
  width: 100%;
}

.ec .resul .nmb.m3 strong:before {
  content: '3,';
}

.ec .resul .nmb.percent strong:after {
  content: '%';
}

.ec .resul .nmb.plus strong:before {
  content: '+';
}

.ec .resul .nmb.minus strong:before {
  content: '-';
}

.ec .nxt {
  width: 100vw;
  overflow: hidden;
}

.ec .nxt .tc {
  color: #edf1f3;
  transition: color 0.2s;
  z-index: 100;
}

.ec .nxt svg {
  fill: #edf1f3;
}

.ec .nxt .bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.maps {
  position: relative;
  width: 100%;
  height: 400px;
}

.customoverlay {
  position: relative;
  bottom: 60px;
  float: left;
  border: 1px solid #ccc;
  border-bottom: 2px solid #ddd;
  border-radius: 6px;
}

.customoverlay::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  width: 22px;
  height: 12px;
  margin-left: -12px;
  background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png');
}

.customoverlay:nth-of-type(n) {
  border: 0;
  box-shadow: 0px 1px 2px #888;
}

.customoverlay a {
  overflow: hidden;
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-align: center;
  background: #27ae60;
  background: #27ae60 url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 14px
    center;
  border-radius: 8px;
}

.customoverlay .title {
  display: block;
  margin-right: 35px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: #fff;
}

.overlay {
  z-index: 50;
  width: 100%;
  height: 100%;
  background: #1e1e1e;
  transform: scaleY(0);
  transform-origin: top;
}

.text-slider-container {
  overflow-x: hidden;
  margin-top: 96px;
  margin-bottom: 96px;
}

.text-slider-container .word {
  display: inline-block;
  text-transform: uppercase;
}

.text-slider-container .word:nth-child(even) {
  -webkit-text-fill-color: #000;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  color: #000;
}

.text-slider-container > span {
  display: block;
  font-size: 100px;
  color: #000;
  white-space: nowrap;
  letter-spacing: 2px;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  -webkit-animation: 20s linear infinite marquee;
  animation: 20s linear infinite marquee;
  animation-play-state: running;
}

@media screen and (max-width: 900px) {
  .text-slider-container > span {
    font-size: 48px;
  }
}

.text-slider-container span.reverse {
  animation: 20s linear infinite marquee-r;
  -webkit-animation: 20s linear infinite marquee-r;
}

.text-slider-paragraph {
  margin-top: 87px;
  max-width: 970px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }

  100% {
    transform: translateX(-150%);
    -webkit-transform: translateX(-150%);
    -moz-transform: translateX(-150%);
    -ms-transform: translateX(-150%);
    -o-transform: translateX(-150%);
  }
}

@keyframes marquee-r {
  0% {
    transform: translateX(-150%);
    -webkit-transform: translateX(-150%);
    -moz-transform: translateX(-150%);
    -ms-transform: translateX(-150%);
    -o-transform: translateX(-150%);
  }

  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

.default {
  position: relative;
  overflow: hidden;
  padding-top: 145px;
  padding-bottom: 90px;
  color: #fff;
}

.default .ct {
  position: relative;
  z-index: 20;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.default h2 {
  max-width: 320px;
}

.default .word {
  display: block;
  font-size: 1.875rem;
  line-height: 1;
}

@media (min-width: 600px) {
  .default {
    padding-top: 220px;
    padding-bottom: 165px;
  }

  .default h2 {
    max-width: 500px;
  }

  .default h2.long {
    max-width: 950px;
  }

  .default .word {
    font-size: 3.125em;
    letter-spacing: -2px;
  }
}

@media (min-width: 1200px) {
  .default {
    padding-top: 285px;
    padding-bottom: 260px;
  }

  .default h2 {
    max-width: 700px;
  }

  .default .word {
    margin: -10px;
    padding: 10px;
    font-size: 4.375em;
    line-height: 1.03;
  }
}

.fill-background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.map {
  position: relative;
  width: 100%;
  height: clamp(360px, 293.333333px + 29.629629vw, 720px);
  background: url(../public/images/map.png) no-repeat 50%;
}

.map:hover {
  --disabled-opacity: 0.1;
}

.map:after {
  background: #000;
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.5s ease-out;
  width: 100%;
  z-index: 5;
}

.svg-box {
  position: absolute;
  max-width: 1560px;
  margin-right: auto;
  margin-left: auto;
  pointer-events: none;
  inset: 0 0 10vmax;
}

.svg-box > svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  inset: 0px;
}

.svg-box > svg path {
  fill: none;
  stroke-dasharray: 1000, 50, 500, 100;
  animation: 5s linear 0s infinite normal none running dash;
}

@keyframes dash {
  to {
    stroke-dashoffset: -1650;
  }
}

.mention-mobile {
  display: none;
}

#ls {
  display: flex;
  min-width: 100%;
  height: 100%;
  padding-bottom: var(--gutter);
  padding-left: var(--padl);
}

#ls > * {
  min-width: var(--wid);
  width: var(--wid);
  height: 100%;
  padding-right: calc(var(--gutter) / var(--diff));
}

#ls > *:last-child {
  margin-right: var(--gutter);
  padding-right: var(--gutter);
}

#ls .trig-cont {
  left: 0;
  width: 100vw;
  height: 0;
}

.cont {
  pointer-events: none;
  z-index: 99;
  padding: 0 calc(20px + 50 * (100vw - 320px) / 1080);
  display: flex;
  align-items: center;
  position: absolute;
  left: var(--gutter);
  top: calc(var(--padt) - var(--gutter));
  width: calc(var(--padl) - var(--gutter) * 2);
  height: calc(var(--hw, 100vh) - var(--padt) - var(--gutter));
}

.cont svg {
  fill: var(--color_1_txt);
  width: calc(200px + 50 * (100vw - 320px) / 1080);
  margin-bottom: calc(20px + 10 * (100vw - 320px) / 1080);
}

.cont h1 {
  font-size: calc(35px + 50 * (100vw - 320px) / 1080);
  font-family: var(--ff_2);
  text-transform: uppercase;
  margin-bottom: var(--gutter);
  text-align: center;
}

.cont h1 small {
  margin-top: calc(15px + 5 * (100vw - 320px) / 1080);
  display: block;
  font-size: calc(14px + 3 * (100vw - 320px) / 1080);
  font-family: var(--ff_1);
  letter-spacing: calc(3px + 2 * (100vw - 320px) / 1080);
}

.more {
  transition: opacity 0.6s;
  position: absolute;
  bottom: 0;
  height: 1px;
  width: calc(35px + 15 * (100vw - 320px) / 1080);
  left: calc(50% + (calc(35px + 15 * (100vw - 320px) / 1080)));
  text-align: center;
  white-space: nowrap;
  margin-top: var(--gutter);
}

.fxdo .more {
  opacity: 0;
}

.more span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(45px + 15 * (100vw - 320px) / 1080);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: calc(2px + 2 * (100vw - 320px) / 1080);
}

.more:before {
  animation: more 2s infinite linear;
  background-color: var(--color_3);
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@keyframes more {
  0% {
    width: 0;
    opacity: 1;
    left: 0;
  }

  40% {
    width: 100%;
    opacity: 1;
    left: 0;
  }

  40.01% {
    width: 100%;
    opacity: 1;
    left: auto;
    right: 0;
  }

  80%,
  100% {
    width: 0;
    opacity: 0;
    left: auto;
    right: 0;
  }
}

.prs {
  --mt: calc(25px + 10 * (100vw - 320px) / 1080);
  background-color: var(--color_2);
  text-align: center;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  overflow: hidden;
}

.prs picture {
  opacity: 1;
  transition: opacity var(--transi), transform var(--transi);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: grayscale(0.7);
}

.mob .prs picture {
  opacity: 0.8;
}

.prs:hover picture {
  opacity: 0.2;
  transform: scale(1.1);
}

.prs picture img {
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  max-width: 150%;
  height: 100%;
  z-index: 0;
}

html.mob .prs picture img {
  width: 100%;
  max-width: 100%;
  object-position: center center;
  transform: none !important;
}

.prs .tx {
  padding: calc(25px + 10 * (100vw - 320px) / 1080) calc(15px + 5 * (100vw - 320px) / 1080);
  z-index: 100;
  color: var(--color_2_txt);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.prs .tx small {
  font-size: 13px;
}

.prs .tx a {
  pointer-events: none;
  display: block;
  margin-top: calc(10px + 5 * (100vw - 320px) / 1080);
  width: 100%;
  overflow: hidden;
  font-size: calc(20px + 10 * (100vw - 320px) / 1080);
  font-family: var(--ff_2);
  text-transform: uppercase;
}

.prs .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(60px + 10 * (100vw - 320px) / 1080);
  z-index: 99;
}

.prs .logo svg {
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  transform: rotate(45deg) rotateX(270deg) scale(0.7);
  fill: #fff;
  opacity: 0;
}

.prs:hover .logo svg {
  transform: rotate(0deg) rotateX(0deg) scale(1);
  opacity: 1;
}

@media screen and (max-width: 1100px) {
  :root {
    --padl: calc(100vw - (var(--wid) / 2) - var(--gutter));
  }
}

@media screen and (max-width: 900px) {
  .mention-mobile {
    display: block;
    margin-top: var(--gutter);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  :root {
    --padl: 100vw;
    --wid: 85vw;
  }

  .cont {
    text-align: center;
    justify-content: center;
  }
}

.scroll__top {
  position: fixed;
  right: 3%;
  bottom: 3%;
  z-index: 1000;
}

.btn__top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
  width: 54px;
  height: 54px;
  font-weight: bold;
  color: #27ae60;
  background-color: #fff;
  border: 1px solid #27ae60;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transform: translateY(100px);
  transition: transform 0.5s;
  will-change: transform;
}

.btn__top.show {
  transform: translateY(0);
}

.btn__top svg {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  fill: #27ae60;
}
